/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
//import './styles/app.css';

// start the Stimulus application
import 'bootstrap';

import { Modal } from 'bootstrap'

import Swiper from 'swiper/bundle';

import Masonry from 'masonry-layout';

import imagesLoaded from 'imagesloaded';


let height = getHeight();
let imgList = document.getElementsByClassName('img-list');
let articleText = document.getElementsByClassName('article-text');

imgList.forEach((el) => {
    el.style.height = height-220+'px';
});
articleText.forEach((el) => {
    el.style.height = height-220+'px';
});

function getHeight()
{
    let height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
    return height;
}

var grid = document.querySelector('.grid');

var msnry;

msnry = new Masonry( grid, {
    itemSelector: '.grid-item',
    columnWidth: '.grid-sizer',
    percentPosition: true,
    horizontalOrder: true,
    transitionDuration: '0.8s',
    initLayout: false
});

imagesLoaded( grid, function() {
    
    msnry.layout();
    
});


let footer = document.querySelector('footer');
const scrollUp = "scroll-up";
const scrollDown = "scroll-down";

window.addEventListener("scroll", () => {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        footer.classList.replace(scrollDown, scrollUp);
    }
    else {
        const currentScroll = window.pageYOffset;
        if (currentScroll > 100) {
            footer.classList.replace(scrollUp, scrollDown);
            return;
        }
        else {
            footer.classList.replace(scrollDown, scrollUp);
        }
    }
});